/* eslint-disable max-classes-per-file */
import { createSelector } from 'reselect';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import filter from 'lodash/filter';
import some from 'lodash/some';
import map from 'lodash/map';
import get from 'lodash/get';
import flatten from 'lodash/flatten';
import indexOf from 'lodash/indexOf';
import size from 'lodash/size';
import compact from 'lodash/compact';
import cloneDeep from 'lodash/cloneDeep';
import intersection from 'lodash/intersection';
import includes from 'lodash/includes';
import extend from 'lodash/extend';
import head from 'lodash/head';
import overEvery from 'lodash/overEvery';
import pickBy from 'lodash/pickBy';
import uniq from 'lodash/uniq';
import identity from 'lodash/identity';
import memoize from 'lodash/memoize';
import ProductConstants, { productDimensions } from '../common/ProductConstants';
import selectProductType from './productDetails/selectProductType';
import { selectContext } from './ContextSelector';
import { templateTypes } from './productDetails/selectProductType.config';
import {
    selectProductDetailsBySource,
    selectProductOptionsBySource,
    selectColorSwatchesBySource,
    selectInventoryBySource,
} from './PdpNameSpaceSelector';

const COLOR_SKU_TOKEN = 'COLOR';
const ORIGINAL = 'ORIGINAL';
const SALE = 'SALE';
const CLEARANCE = 'CLEARANCE';
const DEFAULT = 'DEFAULT';

const defaults = {
    miniPDP: {},
    productOptions: {},
    selectedProductLot: {},
    additionalServices: [],
    productDetails: {},
    inventory: [],
    colorSwatches: [],
};
const productTypesToTurnOffLot = ['largeAppliance'];

export const isSkuLot = (type, updateType, token = 'lot') => [type, updateType].includes(token);

// Avoided isEqual for objects comparison as it is heavy in this case
export const isSelectedOptionsEqual = (filtered) => ({ optionValues }) =>
    intersection(
        map(optionValues, ({ name, value }) => `${name};${value}`),
        map(filtered, ({ name, value }) => `${name};${value}`)
    ).length === filtered.length;

export const isFilterEssential = (
    filterType,
    { id, type, updateType },
    isLot = isSkuLot(type, updateType),
    token = COLOR_SKU_TOKEN
) => id === token || (![id, filterType].includes(token) && !isLot);

function findUniqueImages(arr, prop) {
    return [
        ...arr
            .reduce((resultMap, item) => {
                // const key = (item === null || item === undefined) ? item : item[prop];
                const key = get(item, prop);

                resultMap.has(key) || resultMap.set(key, item);

                return resultMap;
            }, new Map())
            .values(),
    ];
}

class PDPSelectorUtils {
    constructor(prod) {
        this.product = prod;
        this.altImageParam = '';
    }

    getImagesWithoutParams(arr) {
        return arr.map((item) => {
            if (!this.altImageParam && item.url.indexOf('?') !== -1) {
                this.altImageParam = item.url.slice(item.url.indexOf('?'));
            }

            const altParamsIndex =
                item.url.indexOf('?') !== -1 ? item.url.indexOf('?') : item.url.length;
            return { ...item, url: item.url.slice(0, altParamsIndex) };
        });
    }

    isDefaultOptionSelectionEligible = (type, templateType, updated) =>
        (!updated && type === 'lot' && this.product.details.type !== 'GIFTCARD') ||
        templateType === templateTypes.SILVER;

    isInventoryExists() {
        return this.product && this.product.inventory && this.product.inventory.length;
    }

    getProductFiltersForSkuId(skuId, facetSelected) {
        let skuFilters = [];
        const skuItem = find(this.product.filteredItems, { id: skuId });
        if (skuItem) {
            /* istanbul ignore next */
            const selectedDimensions = facetSelected.length
                ? filter(
                      this.product.details.dimensions,
                      ({ type, id = '' }) =>
                          isSkuLot(type) || includes(facetSelected, id.toLowerCase())
                  )
                : this.product.details.dimensions;

            skuFilters = map(selectedDimensions, ({ id, name: dimensionName, options }) => {
                const facetId = id.toLowerCase();
                const { value } = find(skuItem.optionValues, { name: dimensionName }) || {};
                const predicate = {
                    value: (!facetSelected.length || includes(facetSelected, facetId)) && value,
                };

                const skuDimensionOption = find(options, pickBy(predicate, identity)) || options[0];
                return {
                    option: get(skuDimensionOption, 'value'),
                    name: get(skuDimensionOption, 'value'),
                    type: id,
                    dimensionName,
                };
            });

            /* istanbul ignore next */
            if (this.isInventoryExists() && facetSelected.length) {
                const availableItems = some(
                    this.product.allItems,
                    overEvery([
                        isSelectedOptionsEqual(
                            map(skuFilters, ({ option: value, dimensionName: name }) => ({
                                value,
                                name,
                            }))
                        ),
                        ({ id }) => find(this.product.inventory, { id }),
                    ])
                );

                if (!availableItems) {
                    skuFilters = map(skuFilters, (selected) => {
                        const { value } =
                            find(skuItem.optionValues, { name: selected.dimensionName }) || {};
                        selected.option = value;
                        selected.name = value;

                        return selected;
                    });
                }
            }
        }
        return skuFilters;
    }

    getPredefinedDimensionsFromQuery(query) {
        const dimensionIds = map(this.product.dimensions, 'id');
        const queryParams = Object.keys(query);
        return intersection(queryParams, dimensionIds);
    }

    /**
     * [initInventory will return all atp true items]
     * @return {[this.product.allItems]} [description]
     */
    initInventory() {
        this.product.isProductInStock = some(this.product.inventory, { atp: true });
        if (this.product.isProductInStock) {
            // Merge in the inventory data into the allItems array so we don't have to do the
            // lookups multiple times.
            forEach(this.product.allItems, (item, key) => {
                const matchedInventory = find(this.product.inventory, { id: item.id });
                extend(this.product.allItems[key], matchedInventory);
            });
        } else {
            this.product.isItemInStock = false;
        }
    }

    /**
     * getDimensionNameByOption
     */
    getDimensionNameByOption(dimensionName, value, isHybrid = false) {
        const [{ id = '', priceType } = {}] = filter(
            this.product.dimensions,
            ({ name, options }) =>
                name === dimensionName &&
                some(
                    options,
                    dimensionName === 'size' && value
                        ? (x) => x.value.toLowerCase() === value.toLowerCase()
                        : { value }
                )
        );
        return isHybrid && priceType ? priceType : id;
    }

    /**
     * [calculateFilterStatus will check current option value is available or not]
     * @param  {[type]}  filterToCheck [option value]
     * @param {Object} dimension
     * @param {string} dimension.type
     * @param {string} dimension.updateType
     * @param {string} dimension.id
     * @param {string} dimension.name
     */
    calculateFilterStatus(
        filterToCheck,
        {
            type: dimensionType,
            updateType: dimensionUpdateType,
            id: dimensionId,
            name: dimensionName,
        },
        featureFlags = {}
    ) {
        let filterValues = [];
        const filterStatus = {};
        filterStatus.outOfStock = false;
        filterStatus.unavailable = false;
        filterStatus.unavailableOOS = false;
        filterStatus.notAvailable = false;
        const congruentPPsDetails = get(this.product.details, 'congruentOptions', []);
        const isLotAvailable = this.product.currentFilters.find(
            (element) =>
                element.type !== productDimensions.COLOR &&
                !ProductConstants.SIZE_DIMENSIONS.includes(element.type)
        );
        const newFilters = get(featureFlags, 'enableSKUAvailabilityLogic', true)
            ? this.product.currentFilters
            : filter(
                  this.product.currentFilters,
                  ({ type, exclude }) =>
                      !exclude &
                      isFilterEssential(type, {
                          id: dimensionId,
                          type: dimensionType,
                          updateType: dimensionUpdateType,
                      })
              );
        for (let i = 0; i < newFilters.length; i += 1) {
            if (dimensionName !== newFilters[i].dimensionName) {
                filterValues.push({
                    value: newFilters[i].option,
                    name: newFilters[i].dimensionName,
                });
            }
        }
        filterValues.push({ value: filterToCheck, name: dimensionName });
        const isLotDimension = includes(this.product.lotDimension, dimensionId);
        if (get(featureFlags, 'enableSKUAvailabilityLogic', true) && isLotDimension) {
            if (this.product.currentFilters.length === this.product.dimensions.length) {
                if (
                    isLotAvailable &&
                    Object.keys(isLotAvailable) &&
                    congruentPPsDetails?.length === 0
                ) {
                    if (
                        isSkuLot(dimensionType, dimensionUpdateType) &&
                        isLotAvailable.name !== filterToCheck
                    ) {
                        filterValues = filterValues.filter(
                            (filter) =>
                                !ProductConstants.SIZE_DIMENSIONS.includes(
                                    filter.name.toUpperCase()
                                )
                        );
                    }
                }
            }
        }
        const availableItemsAtpTrue = this.product.allItems.some(
            (item) => item.atp && isSelectedOptionsEqual(filterValues)(item)
        );

        const availableItems = this.product.allItems.some(
            (item) =>
                isSelectedOptionsEqual(filterValues)(item) &&
                this.product.inventory &&
                this.product.inventory.find((invItem) => invItem.id === item.id)
        );
        if (!availableItemsAtpTrue) {
            /* istanbul ignore next */
            if (get(featureFlags, 'enableSKUAvailabilityLogic', true)) {
                let notAvailableSize = [];
                if (
                    isLotAvailable &&
                    Object.keys(isLotAvailable) &&
                    ProductConstants.SIZE_DIMENSIONS.includes(dimensionId)
                ) {
                    notAvailableSize = this.product.allItems
                        .filter((data) =>
                            data.optionValues.find((obj) => obj.value === isLotAvailable.name)
                        )
                        .filter((data1) =>
                            data1.optionValues.find(
                                (obj1) =>
                                    ProductConstants.SIZE_DIMENSIONS.includes(
                                        obj1.name.toUpperCase()
                                    ) && obj1.value === filterToCheck
                            )
                        );
                }
                if (availableItems) {
                    filterStatus.outOfStock = true;
                } else if (
                    dimensionId === productDimensions.COLOR ||
                    (notAvailableSize && notAvailableSize.length > 0)
                ) {
                    filterStatus.notAvailable = true;
                } else {
                    filterStatus.unavailableOOS = true;
                }
            } else if (availableItems) {
                const lengthWithoutColor = filter(this.product.dimensions, { id: COLOR_SKU_TOKEN })
                    .length;
                if (
                    !isSkuLot(dimensionType, dimensionUpdateType) ||
                    newFilters.length <= lengthWithoutColor
                ) {
                    filterStatus.outOfStock = true;
                }
            } else {
                filterStatus.unavailable = true;
            }
        }
        return filterStatus;
    }

    /**
     * [setSelectedProductLot after selecting all SKU's find the details]
     */
    setSelectedProductLot(filterValues) {
        const lots = get(this.product, 'details.lots', []);
        if (filterValues) {
            this.product.selectedProductLot = filter(lots, (obj) =>
                find(obj.items, isSelectedOptionsEqual(filterValues))
            );
        } else {
            this.product.selectedProductLot = filter(lots, { isDefault: true });
        }
    }

    /**
     * [setFilterStatus will go the calculation to find the filter status and
     * 1). hide the option that is Unavailable
     * 2). attach the OOS message to option if OOS]
     * @param {[type]} dimensionType [description]
     */
    setFilterStatus(dimensionType, featureFlags = {}) {
        const inventoryLength = get(this.product, 'inventory.length');
        const { dimensions } = this.product;
        const dimensionsLength = dimensions.length;

        for (let i = 0; i < dimensionsLength; i += 1) {
            const dimension = dimensions[i];
            const dimensionId = dimension.id;
            const { options } = dimension;
            const optionsLength = options.length;

            for (let j = 0; j < optionsLength; j += 1) {
                const option = options[j];
                const filterStatus = this.calculateFilterStatus(
                    option.value,
                    dimension,
                    featureFlags
                );

                if (dimensionsLength === 1 || dimensionType !== dimensionId) {
                    option.availableInStock = true;
                    option.availabilityMessage = option.value;
                    option.unavailable = false;
                    if (inventoryLength) {
                        const skuLotCheck = isSkuLot(dimension.type, dimension.updateType);

                        if (filterStatus.unavailable && !skuLotCheck) {
                            option.availableInStock = false;
                            option.availabilityMessage = '';
                        } else if (filterStatus.outOfStock) {
                            option.availableInStock = false;
                            option.availabilityMessage = `${option.value} - ${ProductConstants.OUTOFSTOCK}`;
                        }
                        const congruentPPsDetails = get(
                            this.product.details,
                            'congruentOptions',
                            []
                        );
                        if (get(featureFlags, 'enableSKUAvailabilityLogic', true)) {
                            if (filterStatus.unavailableOOS) {
                                option.unavailable = true;
                                option.availableInStock = false;
                                option.availabilityMessage =
                                    (congruentPPsDetails?.length === 0
                                        ? !skuLotCheck
                                        : skuLotCheck) &&
                                    ![productDimensions.LENGTH, productDimensions.WIDTH].includes(
                                        dimensionId
                                    )
                                        ? ''
                                        : `${option.value} - ${productDimensions.NOTAVAILABLE}`;
                            } else if (filterStatus.notAvailable) {
                                option.unavailable = true;
                                option.availableInStock = false;
                                option.availabilityMessage =
                                    dimensionId === productDimensions.COLOR
                                        ? `${option.value} - ${ProductConstants.OUTOFSTOCK}`
                                        : `${option.value} - ${productDimensions.NOTAVAILABLE}`;
                            }
                        }
                    }
                } else if (!('availableInStock' in option)) {
                    option.availableInStock = true;
                    option.availabilityMessage = option.value;
                }
            }
        }
    }

    /**
     * [setFilteredItems To set the filtereditems based on current filter/s]
     */
    setFilteredItems(featureFlags = {}) {
        const filterValues = [];
        forEach(this.product.currentFilters, ({ option: value, dimensionName: name }) => {
            if (!find(filterValues, { name })) {
                filterValues.push({ value, name });
            }
        });
        this.product.filteredItems = filter(
            this.product.allItems,
            isSelectedOptionsEqual(filterValues)
        );
        forEach(this.product.currentFilters, (filtering) => {
            this.setFilterStatus(filtering.type, featureFlags);
        });
    }

    /**
     * [removeFilters description]
     * @param  {[type]} filtersToBeRemoved [description]
     * @return {[type]}                    [description]
     */
    removeFilters(filtersToBeRemoved) {
        this.product.currentFilters = filter(
            this.product.currentFilters,
            (option) => option.type !== filtersToBeRemoved
        );
    }

    /**
     * [removeAllFilters description]
     * @return {[type]} [description]
     */
    // istanbul ignore next
    removeAllFilters() {
        this.product.currentFilters = [];
    }

    /**
     * resetFilters
     */
    resetFilters(filtersToBeReset) {
        const isLotDimension = includes(this.product.lotDimension, filtersToBeReset);
        const isUpdatedLotDimension = includes(this.product.updatedLotDimension, filtersToBeReset);
        const headFilter = head(this.product.currentFilters);
        if (
            (!isLotDimension && !isUpdatedLotDimension) ||
            get(headFilter, 'type') === COLOR_SKU_TOKEN
        ) {
            this.removeFilters(filtersToBeReset);
        } else {
            this.removeAllFilters();
        }
    }

    /* istanbul ignore next */
    setItemImages(dimension = {}, featureFlags = {}) {
        if (dimension.type === productDimensions.COLOR) {
            const cloneImages = cloneDeep(this.product.details.images);
            const altImages = get(dimension, 'options.altImages', []);
            const productImage = get(dimension, 'options.productImage', {});
            const primaryImage = productImage.url
                ? productImage
                : get(this.product, 'details.images[0]');
            if (cloneImages && altImages.length && featureFlags.changeAltImagesColor) {
                // replace the first x number of images with altImages, except the first primary image
                map(altImages, (image, index) => {
                    if (cloneImages[index + 1]) {
                        cloneImages[index + 1].url = image.url;
                    } else {
                        cloneImages[index + 1] = {
                            url: image.url,
                            type: 'ALTERNATIVE',
                            altText: altImages[0].altText,
                        };
                    }
                });

                // replace the primary image seperately
                if (primaryImage && primaryImage.url) {
                    cloneImages[0] = primaryImage;
                }
            } else if (cloneImages) {
                cloneImages[0] = primaryImage && primaryImage.url ? primaryImage : cloneImages[0];
            }

            const resultImages =
                cloneImages &&
                findUniqueImages(this.getImagesWithoutParams(cloneImages), 'url').map((item) => ({
                    ...item,
                    url: item.url + this.altImageParam,
                }));

            this.product.images = resultImages;
        }
    }

    getOptionDetailsByOption(dimensionName, option, priceType) {
        const congruentPPsDetails = get(this.product.details, 'congruentDimensions', []);
        this.product.dimensions = this.product.dimensions || congruentPPsDetails;
        const type = this.getDimensionNameByOption(
            dimensionName,
            option,
            get(this.product, 'details.hybridIndicator')
        );
        let optionDetails;
        let dimension;

        dimension =
            find(this.product.dimensions, (dimens) => dimens.id === type) ||
            find(congruentPPsDetails, (dimens) => dimens.name === dimensionName) ||
            {};
        if (type !== '') {
            /* istanbul ignore next */
            if (this.product.details.hybridIndicator) {
                dimension =
                    find(this.product.dimensions, (dimens) => dimens.priceType === type) || {};
            } else {
                dimension = find(this.product.dimensions, (dimens) => dimens.id === type) || {};
            }

            if (
                dimension.name === 'size' &&
                dimension.options.some(
                    (x) => x.value.match(/[a-z]/gi) && x.value === x.value.toUpperCase()
                )
            ) {
                option = option.toUpperCase();
            }

            const options = find(dimension.options, { value: option });
            optionDetails = {
                type: dimension.id,
                dimensionName: dimension.name,
                options,
                priceType,
            };
        } else {
            const options = find(dimension.options, { value: option });
            const isTypeAvailable = some(this.product.congruentFilters, { type: dimensionName });
            optionDetails = {
                type: dimensionName,
                dimensionName,
                options,
                priceType,
                isCongruent: true,
            };
            if (options) {
                if (!isTypeAvailable) {
                    this.product.congruentFilters = [
                        ...this.product.congruentFilters,
                        optionDetails,
                    ];
                } else {
                    this.product.congruentFilters = this.product.congruentFilters.map((item) => {
                        if (item.dimensionName === option.dimensionName) {
                            return optionDetails;
                        }
                        return item;
                    });
                }
            }
        }
        return optionDetails;
    }

    /**
     * [setCurrentFilters will set the lot or if single dimension available set in currentfilters]
     * @return {[this.product.currentFilters]} [return option id and dimension name]
     */
    setCurrentFilters(
        query,
        productType,
        shouldRender = true,
        featureFlags = {},
        congruentSelected = {},
        miniPDP
    ) {
        const isCongruent = get(congruentSelected, 'isCongruent', false);
        const selectedSKU = query && !isCongruent && (query.selectedSKUId || query.sku);
        const facetSelected =
            query && !isCongruent && query.facetSelected ? query.facetSelected.split(',') : [];
        // Below changes added as part of YODATOF-1152
        const facetSelectedModify = facetSelected.map((item) => {
            return item.replace(/ /g, '_');
        });
        const filtersForSkuId = this.getProductFiltersForSkuId(
            selectedSKU,
            compact(facetSelectedModify)
        );
        if (shouldRender && query) {
            if (filtersForSkuId.length) {
                this.product.currentFilters = [];
                this.product.currentFilters = [...this.product.currentFilters, ...filtersForSkuId];
                const miniPDPCallFromWidgets =
                    (miniPDP.miniPDPSTL && miniPDP.miniPDPSTL === 'open') ||
                    (miniPDP.miniPDPDG && miniPDP.miniPDPDG === 'open') ||
                    (miniPDP.miniPDP_SFY && miniPDP.miniPDP_SFY === 'open');
                if (miniPDP && miniPDP.showMiniPDP && miniPDPCallFromWidgets) {
                    this.product.currentFilters = this.product.currentFilters.filter(
                        (item) => !['WIDTH', 'SIZE', 'CHEST'].includes(item.type)
                    );
                }
            } else {
                const predefinedDimensions = this.getPredefinedDimensionsFromQuery(query);

                // 1. find needed dimension in dimensions for predefined option, color, for example
                /* istanbul ignore next */
                predefinedDimensions.forEach((dimensionId) => {
                    const dimension = find(this.product.dimensions, { id: dimensionId });

                    // 2. find option id where value equals khaki, for example
                    const predefinedOptions = dimension.options.filter(
                        (option) =>
                            option.value.toLowerCase() ===
                            (Array.isArray(query[dimensionId])
                                ? query[dimensionId][0].toLowerCase()
                                : query[dimensionId].toLowerCase())
                    );

                    // 3. set current filter
                    if (predefinedOptions[0]) {
                        const option = {
                            option: get(predefinedOptions, [0, 'value'], ''),
                            type: dimensionId,
                            name: get(predefinedOptions, [0, 'value'], ''),
                            dimensionName: dimension.name,
                        };
                        this.product.currentFilters = [];
                        if (
                            !this.product.details.hybridIndicator &&
                            !find(this.product.currentFilters, { type: option.type })
                        ) {
                            this.product.currentFilters = [...this.product.currentFilters, option];
                        }
                    }
                });
            }
            // Loop though the dimensions array and prepare data for faster lookup.
            forEach(
                this.product.dimensions,
                ({ options, type, id, name: dimensionName, updated }) => {
                    const filterOption = filter(
                        options,
                        (optionValue) => optionValue.availabilityMessage !== ''
                    );
                    let isTypeAvailable = some(this.product.currentFilters, { type: id });
                    let congruentMatchingOptions = [];
                    if (Object.keys(congruentSelected).length > 0) {
                        const replaceClickedValue = congruentSelected.optionsSelected;
                        congruentMatchingOptions = replaceClickedValue.filter((item) => {
                            return options.some(
                                (option) =>
                                    dimensionName === item.dimensionName &&
                                    option.value === item.name
                            );
                        });
                    }
                    if (
                        congruentMatchingOptions?.length > 0 &&
                        get(featureFlags, 'enableProductSelection', false) &&
                        !isTypeAvailable
                    ) {
                        this.product.currentFilters = [
                            ...this.product.currentFilters,
                            ...congruentMatchingOptions,
                        ];
                    }
                    isTypeAvailable = some(this.product.currentFilters, { type: id });
                    if (
                        filtersForSkuId.length === 0 &&
                        get(featureFlags, 'enableSKUAvailabilityLogic', true) &&
                        isTypeAvailable &&
                        id === productDimensions.PRODUCT
                    ) {
                        this.product.currentFilters = [];
                    }
                    const selectDefaultOption = this.isDefaultOptionSelectionEligible(
                        type,
                        get(productType, 'templateType'),
                        updated
                    );
                    let shouldExecuteCurrentFilters = true;
                    const ppFeatureColorAvailable = filterOption.findIndex(
                        (element) =>
                            element.value.toLowerCase() ===
                            this.product.ppFeatureColor.toLowerCase()
                    );
                    // istanbul ignore if
                    if (
                        this.product.details.hybridIndicator &&
                        id === COLOR_SKU_TOKEN &&
                        filterOption.length === 1
                    ) {
                        shouldExecuteCurrentFilters = false;
                    }
                    if (id === COLOR_SKU_TOKEN && filtersForSkuId.length) {
                        const { option: selectedColorOption } =
                            find(filtersForSkuId, { type: COLOR_SKU_TOKEN }) || {};
                        const itemImageDimension = this.getOptionDetailsByOption(
                            dimensionName,
                            selectedColorOption
                        );
                        this.setItemImages(itemImageDimension, featureFlags);
                    }
                    if (
                        shouldExecuteCurrentFilters &&
                        !selectDefaultOption &&
                        filterOption.length === 1
                    ) {
                        this.removeFilters(id);
                        const option = {
                            option: get(filterOption, [0, 'value'], ''),
                            type: id,
                            name: get(filterOption, [0, 'value'], ''),
                            dimensionName,
                            exclude: (filterOption.length === 1 && id === COLOR_SKU_TOKEN) || false,
                        };
                        this.product.currentFilters = [...this.product.currentFilters, option];
                        this.setFilteredItems(featureFlags);
                    } else if (
                        shouldExecuteCurrentFilters &&
                        !isTypeAvailable &&
                        (selectDefaultOption || filterOption.length === 1)
                    ) {
                        const filterOptionData = get(
                            featureFlags,
                            'enableSKUAvailabilityLogic',
                            true
                        )
                            ? filterOption.length > 0 &&
                              filterOption[0].availabilityMessage &&
                              filterOption.filter((data) => data.availableInStock === true)
                            : filterOption;
                        const filterOptionAvailable =
                            featureFlags.enableMiniPDPSkuDefaultSelection && miniPDP.showMiniPDP
                                ? filterOptionData && filterOptionData.length > 0
                                    ? filterOptionData
                                    : filterOption
                                : filterOptionData;
                        if (filterOptionAvailable && filterOptionAvailable.length > 0) {
                            const option = {
                                option: get(filterOptionAvailable, [0, 'value'], ''),
                                type: id,
                                name: get(filterOptionAvailable, [0, 'value'], ''),
                                dimensionName,
                                exclude: filterOptionAvailable.length === 1 || false,
                            };
                            this.product.currentFilters = [...this.product.currentFilters, option];
                        }
                    } else if (
                        get(featureFlags, 'enablePPFeatureFlag', true) &&
                        !isTypeAvailable &&
                        dimensionName === 'color' &&
                        ppFeatureColorAvailable !== -1 &&
                        !this.product.details.hybridIndicator
                    ) {
                        const option = {
                            option: this.product.ppFeatureColor,
                            type: id,
                            name: this.product.ppFeatureColor,
                            dimensionName,
                        };
                        this.product.currentFilters = [...this.product.currentFilters, option];
                    }
                }
            );
        }
    }

    /**
     * [addFilters, it will push all selected dimension to an array]
     * @param  {[type]} dimension name
     * @param  {[option]} value
     */

    addFilters(
        dimension,
        value,
        congruentSelected = {},
        featureFlags = { retainSkuSelection: true, enableSKUAvailabilityLogic: true },
        filteredValue = false
    ) {
        const selectedFilter =
            dimension &&
            find(this.product.currentFilters, { dimensionName: dimension.dimensionName });
        const selectedCongruentFilter =
            dimension &&
            find(this.product.congruentFilters, { dimensionName: dimension.dimensionName });
        const congruentDimension =
            find(
                this.product.dimensions,
                (dimens) =>
                    dimens.name === dimension.dimensionName &&
                    dimens.options.some((option) => option.value === value)
            ) || {};
        if (
            (dimension && value && !selectedFilter) ||
            (dimension &&
                value &&
                selectedCongruentFilter &&
                Object.keys(congruentDimension).length === 0)
        ) {
            const option = {
                option: value,
                type: selectedCongruentFilter
                    ? get(dimension, 'type')?.split(' ')?.join('_')?.toUpperCase()
                    : get(dimension, 'type'),
                name: get(dimension, 'options.value'),
                dimensionName: get(dimension, 'dimensionName'),
                priceType: get(dimension, 'priceType'),
            };
            if (dimension && value && Object.keys(congruentDimension).length === 0) {
                this.product.currentFilters = this.product.currentFilters.map((item) => {
                    if (item.dimensionName === option.dimensionName) {
                        return option;
                    }
                    return item;
                });
            } else {
                this.product.currentFilters = [...this.product.currentFilters, option];
            }
            /* istanbul ignore next */
            if (this.isInventoryExists() && !isSkuLot(dimension.type, dimension.updateType)) {
                const availableItems = some(
                    this.product.allItems,
                    overEvery([
                        isSelectedOptionsEqual(
                            map(
                                this.product.currentFilters,
                                ({ option: filterValue, dimensionName: name }) => ({
                                    value: filterValue,
                                    name,
                                })
                            )
                        ),
                        ({ id }) => find(this.product.inventory, { id }),
                    ])
                );

                if (!availableItems) {
                    if (!featureFlags.retainSkuSelection) {
                        this.resetFilters(COLOR_SKU_TOKEN);
                    } else {
                        const toBeReset = this.product.currentFilters.reduce(
                            (resetFilter, currentFilter) => {
                                if (!resetFilter || resetFilter === COLOR_SKU_TOKEN) {
                                    if (
                                        currentFilter.dimensionName !== dimension.dimensionName &&
                                        !(
                                            includes(
                                                this.product.lotDimension,
                                                currentFilter.type
                                            ) ||
                                            includes(
                                                this.product.updatedLotDimension,
                                                currentFilter.type
                                            )
                                        )
                                    ) {
                                        resetFilter = currentFilter.type;
                                    }
                                }
                                return resetFilter;
                            },
                            null
                        );
                        if (Object.keys(congruentDimension).length > 0) {
                            if (
                                get(featureFlags, 'enableSKUAvailabilityLogic', true) &&
                                !this.product.details.hybridIndicator
                            ) {
                                if (
                                    filteredValue &&
                                    toBeReset === productDimensions.SIZE &&
                                    dimension.type !== productDimensions.COLOR
                                ) {
                                    this.removeFilters(toBeReset);
                                }
                            } else {
                                this.removeFilters(toBeReset);
                            }
                        }
                    }
                }
            }
        }
        this.setFilteredItems(featureFlags);
        this.setCurrentFilters(null, null, null, featureFlags);
    }

    /**
     * getAllLotsDimension
     */
    getAllLotsDimension() {
        const lotAvailableDimension = filter(this.product.dimensions, { type: 'lot' });
        const UpdatedLotAvailableDimension = filter(this.product.dimensions, { updateType: 'lot' });
        forEach(lotAvailableDimension, ({ id }) => {
            this.product.lotDimension.push(id);
        });
        forEach(UpdatedLotAvailableDimension, ({ id }) => {
            this.product.updatedLotDimension.push(id);
        });

        this.product.lotDimension = uniq(this.product.lotDimension);
        this.product.updatedLotDimension = uniq(this.product.updatedLotDimension);
    }

    /**
     * [calculatePricingURL description]
     * @param  {[type]} URL [description]
     * @return {[type]}     [description]
     */
    calculatePricingURL(URL) {
        if (!URL) {
            this.product.priceURL = {
                itemOfferingCall: false,
            };
        } else {
            const customizationModelType =
                this.product.selectedProductLot[0] &&
                this.product.selectedProductLot[0].customizationModel &&
                this.product.selectedProductLot[0].customizationModel.type;
            const isCustomOption =
                customizationModelType === ProductConstants.MADE_TO_MEASURE ||
                customizationModelType === ProductConstants.HEMMING ||
                customizationModelType === ProductConstants.GIFTCARD;
            const isTruckable =
                this.product.selectedProductLot[0] &&
                this.product.selectedProductLot[0].shipping &&
                this.product.selectedProductLot[0].shipping.truckable === true;
            const holidayName =
                this.product.selectedProductLot[0] &&
                this.product.selectedProductLot[0].occasionName;
            const productSubdivision =
                this.product.selectedProductLot[0] &&
                this.product.selectedProductLot[0].organization &&
                this.product.selectedProductLot[0].organization.subdivision;
            this.product.priceURL = {
                URL,
                itemOfferingCall: true,
                isCustomOption,
                isTruckable,
                holidayName,
                productSubdivision,
            };
        }
    }

    /**
     * [setSelectedLotDetails onchange of lot in dimensions set the details of the lot]
     */
    setSelectedLotDetails() {
        const filterValues = map(
            this.product.currentFilters,
            ({ option: value, dimensionName: name }) => ({ value, name })
        );
        this.product.selectedLotDetails =
            filter(get(this.product, 'details.lots', []), (obj) =>
                find(obj.items, isSelectedOptionsEqual(filterValues))
            )[0] || this.product.selectedLotDetails;
    }

    setItemOfferingsPriceURL() {
        let offeringsPriceURL;
        if (this.product.dimensions.length === 0) {
            offeringsPriceURL = this.product.allItems[0] && this.product.allItems[0].offering.href;
            this.calculatePricingURL(offeringsPriceURL);
        } else if (
            this.product.dimensions.length === this.product.currentFilters.length ||
            (this.product.details.hybridIndicator &&
                this.product.dimensions.length === this.product.currentFilters.length + 2)
        ) {
            const filterValues = map(
                this.product.currentFilters,
                ({ option: value, dimensionName: name }) => ({ value, name })
            );
            let availableItems;
            availableItems = find(
                this.product.filteredItems,
                overEvery([({ atp }) => atp, isSelectedOptionsEqual(filterValues)])
            );
            if (!availableItems) {
                availableItems = find(
                    this.product.filteredItems,
                    isSelectedOptionsEqual(filterValues)
                );
            }
            offeringsPriceURL =
                availableItems && availableItems.offering ? availableItems.offering.href : '';
            this.setSelectedProductLot(filterValues);
            this.calculatePricingURL(offeringsPriceURL);
        } else {
            this.calculatePricingURL(offeringsPriceURL);
            this.setSelectedLotDetails();
        }
    }

    setItemInStockStatus(dimensionName, option) {
        if (size(this.product.currentFilters) > 0) {
            const optionsDetails = this.getOptionDetailsByOption(dimensionName, option);
            this.product.isItemInStock =
                optionsDetails && optionsDetails.options && optionsDetails.options.availableInStock;
        }
    }

    getCurrentFilterColorOptionDetails(dimensionName) {
        const currentColorFilter = find(this.product.currentFilters, {
            type: productDimensions.COLOR,
        });
        return currentColorFilter
            ? this.getOptionDetailsByOption(dimensionName, currentColorFilter.option)
            : {};
    }

    resetProductObj() {
        this.product.currentFilters = [];
        this.product.filteredItems = [];
        this.product.dimensions = [];
        this.product.isProductInStock = '';
        this.product.lotDimension = [];
        this.product.updatedLotDimension = [];
        this.product.allItems = [];
        this.product.details = {};
        this.product.inventory = [];
        this.product.priceURL = {};
        this.product.images = [];
        this.product.isItemInStock = true;
        this.product.selectedProductLot = [];
        this.product.selectedLotDetails = {};
        this.product.hybridPrice = {};
        this.product.myAlertIndicator = '';
        this.product.congruentFilters = [];
    }

    setLotDimensions() {
        if (
            this.product.details.dimensions &&
            this.product.details.dimensions.length &&
            this.product.details.dimensions.length > 1
        ) {
            const dimensions = [...this.product.details.dimensions];
            const lotAvailableDimension = filter(this.product.dimensions, { type: 'lot' });
            if (!lotAvailableDimension.length) {
                forEach(dimensions, (dimension, index) => {
                    if (index === 0) {
                        dimension.updateType = 'lot';
                        dimension.updated = true;
                    }
                });
            }
        }
    }

    /* istanbul ignore next */
    reCreateDimensions({ featureFlags = {} }) {
        /* istanbul ignore next */
        if (this.product.details.dimensions && this.product.details.dimensions.length) {
            const dimensions = [...this.product.details.dimensions];
            const color = find(dimensions, { id: COLOR_SKU_TOKEN });
            const isNotOriginal = (amount) => amount.type.toUpperCase() !== ORIGINAL;
            const isOriginal = (amount) => amount.type.toUpperCase() === ORIGINAL;
            const isNotSale = (amount) => amount.type.toUpperCase() !== SALE;
            const isNotClearance = (amount) => amount.type.toUpperCase() !== CLEARANCE;

            if (color) {
                const PRICE_TYPE = {
                    original: ORIGINAL.toLowerCase(),
                    sale: SALE.toLowerCase(),
                    clearance: CLEARANCE.toLowerCase(),
                };
                /* istanbul ignore next */
                PRICE_TYPE.original = ORIGINAL;
                PRICE_TYPE.sale = SALE;
                PRICE_TYPE.clearance = CLEARANCE;
                const removeColor = filter(
                    dimensions,
                    (dimension) => dimension.id !== COLOR_SKU_TOKEN
                );
                const combineColors = [
                    { priceType: PRICE_TYPE.original, ...color },
                    { priceType: PRICE_TYPE.sale, ...color },
                    { priceType: PRICE_TYPE.clearance, ...color },
                ];
                this.product.dimensions = [...removeColor, ...combineColors];
                let countSaleandClearence = 0;
                const allClearence = [];
                const allSale = [];
                const allOriginal = [];
                if (this.product.itemPrices) {
                    // Suppressed the 'DEFAULT' price type from API response
                    this.product.itemPrices.data.forEach((itemPrice) => {
                        itemPrice.amounts = filter(
                            get(itemPrice, 'amounts', []),
                            (amount) => amount.type !== DEFAULT
                        );
                    });
                    const filterValues = [];
                    /* istanbul ignore next */
                    forEach(
                        this.product.currentFilters,
                        ({ option: value, dimensionName: name, type }) => {
                            if (type !== COLOR_SKU_TOKEN) {
                                filterValues.push({ value, name });
                            }
                        }
                    );
                    /* istanbul ignore next */
                    forEach(this.product.dimensions, (dimension) => {
                        if (dimension.id === COLOR_SKU_TOKEN) {
                            forEach(dimension.options, (option) => {
                                const optionValues = [
                                    ...filterValues,
                                    { value: option.value, name: dimension.name },
                                ];
                                const filterOption = filter(
                                    this.product.allItems,
                                    isSelectedOptionsEqual(optionValues)
                                );
                                const priceTypeArray = [];
                                let clearance = [];
                                let sale = [];
                                let original = [];
                                forEach(filterOption, (skuId) => {
                                    const selectedItemPrices = filter(
                                        this.product.itemPrices.data,
                                        { id: skuId.id }
                                    )[0];
                                    let notOriginal = null;
                                    let checkOriginal = true;
                                    if (
                                        selectedItemPrices &&
                                        optionValues.length ===
                                            this.product.details.dimensions.length &&
                                        filterOption.length > 1
                                    ) {
                                        /* istanbul ignore next */
                                        notOriginal = filter(
                                            selectedItemPrices.amounts,
                                            (amount) => isNotOriginal(amount) && isNotSale(amount)
                                        )[0];
                                        checkOriginal = false;
                                        /* istanbul ignore next */
                                        if (notOriginal === undefined) {
                                            skuId.atp = false;
                                        }
                                    }
                                    if (selectedItemPrices && notOriginal !== undefined) {
                                        if (!notOriginal) {
                                            notOriginal = filter(
                                                selectedItemPrices.amounts,
                                                (amount) => isNotOriginal(amount)
                                            )[0];
                                        }
                                        clearance = filter(
                                            selectedItemPrices.amounts,
                                            (amount) => isNotOriginal(amount) && isNotSale(amount)
                                        )[0];
                                        sale = filter(
                                            selectedItemPrices.amounts,
                                            (amount) =>
                                                isNotOriginal(amount) && isNotClearance(amount)
                                        )[0];
                                        /* istanbul ignore next */
                                        original =
                                            selectedItemPrices.amounts.length === 1
                                                ? filter(selectedItemPrices.amounts, (amount) =>
                                                      isOriginal(amount)
                                                  )[0]
                                                : null;
                                    }
                                    allClearence.push(clearance);
                                    allSale.push(sale);
                                    allOriginal.push(original);
                                    /* istanbul ignore next */
                                    if (notOriginal === undefined && checkOriginal) {
                                        /* istanbul ignore next */
                                        notOriginal = filter(selectedItemPrices.amounts, (amount) =>
                                            isOriginal(amount)
                                        )[0];
                                    }
                                    const priceType = notOriginal
                                        ? get(notOriginal, 'type', '').toLowerCase()
                                        : null;
                                    priceTypeArray.push(priceType);
                                });
                                option.remove = false;
                                if (
                                    indexOf(
                                        priceTypeArray,
                                        get(dimension, 'priceType', '').toLowerCase()
                                    ) === -1
                                ) {
                                    option.remove = true;
                                }
                            });
                            const filterDimension = filter(
                                dimension.options,
                                (optionValue) => optionValue.remove === false
                            );
                            dimension.options = filterDimension;
                            dimension.isSaleandClearence = true;
                            dimension.priceDisplay = true;
                            if (filterDimension.length === 0) {
                                countSaleandClearence += 1;
                            }
                            if (countSaleandClearence === 2) {
                                dimension.priceDisplay = false;
                            }
                            this.product.hybridPrice = {
                                allClearence,
                                allSale,
                                allOriginal,
                            };
                        } else {
                            dimension.priceType = dimension.name;
                            dimension.isSaleandClearence = false;
                        }
                    });
                }
            } else {
                this.product.details.hybridIndicator = false;
            }
        }
    }
}

const baseProduct = new PDPSelectorUtils({
    currentFilters: [],
    filteredItems: [],
    dimensions: [],
    isProductInStock: '',
    lotDimension: [],
    updatedLotDimension: [],
    allItems: [],
    details: {},
    inventory: [],
    priceURL: {},
    images: [],
    isItemInStock: true,
    selectedProductLot: [],
    selectedLotDetails: [],
    hybridPrice: {},
    myAlertIndicator: '',
    congruentFilters: [],
});
const miniProduct = new PDPSelectorUtils({
    currentFilters: [],
    filteredItems: [],
    dimensions: [],
    isProductInStock: '',
    lotDimension: [],
    updatedLotDimension: [],
    allItems: [],
    details: {},
    inventory: [],
    priceURL: {},
    images: [],
    isItemInStock: true,
    selectedProductLot: [],
    selectedLotDetails: [],
    hybridPrice: {},
    myAlertIndicator: '',
    congruentFilters: [],
});

class ProductModule {
    static getShowHidelotFlag(productType) {
        return productTypesToTurnOffLot.includes(productType);
    }

    static getItemInStockStatus(productUtil) {
        return get(productUtil, 'product.isItemInStock', false);
    }

    /**
     * [removeUnavailableItem, it will remove all unavailable Items]
     */
    static removeUnavailableFilters(dimension) {
        const filterDimension = filter(
            dimension.options,
            (optionValue) => optionValue.availabilityMessage !== ''
        );
        return { ...dimension, options: filterDimension };
    }
}

export default ProductModule;

export const getQueryParams = (state) => state.queryParams;
export const getCongruentSelected = (state) => state.congruentSelected;
const getFeatureFlag = selectContext;
export const getMiniPDP = (state) => state.miniPDP || defaults.miniPDP;
// const getColorSwatches = state => state.colorSwatches || defaults.colorSwatches;

export const productImageOnhover = memoize((sourceMiniPDP) =>
    createSelector(
        [selectColorSwatchesBySource(sourceMiniPDP), getFeatureFlag],
        ({ hoverOption, isSwatchesOnHover, updateProductImage }, featureFlag) => {
            const productUtil = sourceMiniPDP ? miniProduct : baseProduct;
            if (updateProductImage) {
                const dimension = productUtil.getOptionDetailsByOption('color', hoverOption);
                if (get(dimension, 'type')) {
                    productUtil.setItemImages(dimension, featureFlag.featureFlags);
                }
            }
            return {
                updateProductImage,
                isSwatchesOnHover,
                productImages: productUtil.product.images,
            };
        }
    )
);

export const initProductOptions = memoize((sourceMiniPDP) =>
    createSelector(
        [
            selectProductDetailsBySource(sourceMiniPDP),
            selectInventoryBySource(sourceMiniPDP),
            selectProductOptionsBySource(sourceMiniPDP),
            getQueryParams,
            selectProductType(sourceMiniPDP),
            getFeatureFlag,
            getMiniPDP,
            getCongruentSelected,
        ],
        (
            productDetails,
            inventory,
            changeFilter,
            queryParams,
            productType,
            featureFlag,
            miniPDP,
            congruentSelected
        ) => {
            const productUtil = sourceMiniPDP ? miniProduct : baseProduct;
            if (Object.keys(changeFilter).length > 0) {
                const optionValue = changeFilter.option;
                const dimension = productUtil.getOptionDetailsByOption(
                    changeFilter.dimensionName,
                    optionValue,
                    changeFilter.priceType
                );
                const featureFlags = get(featureFlag, 'featureFlags', {});
                const retainSkuSelection = get(featureFlags, 'retainSkuSelection', true);
                if (dimension) {
                    if (!retainSkuSelection) {
                        productUtil.resetFilters(dimension.type);
                    } else {
                        productUtil.removeFilters(dimension.type);
                    }
                    if (get(featureFlags, 'enableSKUAvailabilityLogic', true)) {
                        productUtil.addFilters(
                            dimension,
                            optionValue,
                            congruentSelected,
                            featureFlags,
                            true
                        );
                    } else {
                        productUtil.addFilters(
                            dimension,
                            optionValue,
                            congruentSelected,
                            featureFlags
                        );
                    }
                    if (productDetails.hybridIndicator) {
                        productUtil.reCreateDimensions(featureFlag);
                        productUtil.setFilteredItems(featureFlags);
                    }
                    productUtil.setItemOfferingsPriceURL();
                    productUtil.setItemInStockStatus(dimension.dimensionName, optionValue);
                    productUtil.setItemImages(dimension, featureFlag.featureFlags);
                }
            } else if (productDetails && productDetails.id) {
                if (
                    productUtil.product.details.id &&
                    productUtil.product.details.id !== productDetails.id
                ) {
                    productUtil.resetProductObj();
                }
                productUtil.product.details = productDetails;
                // default it to productDetails.images
                productUtil.product.images = productUtil.product.details.images;
                productUtil.product.inventory =
                    inventory && inventory[0] ? inventory[0].inventory : '';
                productUtil.product.itemPrices =
                    inventory && inventory[0] && inventory[0].itemPrices
                        ? inventory[0].itemPrices
                        : '';
                productUtil.product.dimensions = productDetails.dimensions
                    ? productDetails.dimensions
                    : [];
                productUtil.product.allItems = compact(
                    flatten(map(productUtil.product.details.lots, 'items'), true)
                );
                productUtil.product.filteredItems = productUtil.product.allItems;
                productUtil.product.ppFeatureColor = productDetails.ppFeatureColor
                    ? productDetails.ppFeatureColor
                    : '';
                /* istanbul ignore next */
                const inventoryAlertIndicator =
                    inventory && inventory[0] ? 'myAlertIndicator' in inventory[0] : '';
                if (inventoryAlertIndicator) {
                    productUtil.product.myAlertIndicator = inventory[0].myAlertIndicator;
                }
                if (
                    (featureFlag &&
                        featureFlag.featureFlags &&
                        !featureFlag.featureFlags.combineSaleandClearance) ||
                    (productUtil.product.inventory !== '' && productUtil.product.itemPrices === '')
                ) {
                    productDetails.hybridIndicator = false;
                }
                if (productDetails.hybridIndicator) {
                    productUtil.reCreateDimensions(featureFlag);
                }
                productUtil.setLotDimensions();
                productUtil.getAllLotsDimension();
                if (productUtil.product.inventory !== '') {
                    productUtil.initInventory();
                }
                productUtil.setCurrentFilters(
                    miniPDP.showMiniPDP ? miniPDP : queryParams,
                    productType,
                    !__SERVER__,
                    featureFlag.featureFlags,
                    congruentSelected,
                    miniPDP
                );
                productUtil.setFilterStatus(null, featureFlag.featureFlags);
                if (productUtil.product.currentFilters.length !== 0) {
                    productUtil.setFilteredItems(featureFlag.featureFlags);
                    if (productUtil.product.dimensions.length === 1) {
                        productUtil.setItemInStockStatus(
                            productUtil.product.dimensions[0].name,
                            productUtil.product.dimensions[0].options[0].value
                        );
                    }
                }
                productUtil.setItemOfferingsPriceURL();
                productUtil.setItemImages(
                    productUtil.getCurrentFilterColorOptionDetails(
                        get(productUtil.product, 'dimensions[0].name')
                    ),
                    featureFlag.featureFlags
                );
                let hasFilteredValues = '';
                /* istanbul ignore next */
                if (
                    (queryParams && (queryParams.wty || queryParams.facetSelected)) ||
                    miniPDP.showMiniPDP
                ) {
                    hasFilteredValues = map(
                        productUtil.product.currentFilters,
                        ({ option: value, dimensionName: name }) => ({ value, name })
                    );
                }
                productUtil.setSelectedProductLot(hasFilteredValues);
                productUtil.setSelectedLotDetails();
            } else if (productDetails && !Object.keys(productDetails).length) {
                productUtil.resetProductObj();
            }
            productUtil.product.currentFilters.forEach((currFilter) => {
                const productDimension = filter(
                    productDetails.dimensions,
                    (currProductDimension) => currProductDimension.id === currFilter.type
                );
                const filterStatus = productUtil.calculateFilterStatus(
                    currFilter.option,
                    productDimension[0],
                    featureFlag.featureFlags
                );
                if (filterStatus.unavailable) {
                    productUtil.removeFilters(productDimension[0].id);

                    const dimension = {
                        type: currFilter.type,
                        dimensionName: currFilter.dimensionName,
                        options: productDimension[0].options,
                    };
                    productUtil.addFilters(
                        dimension,
                        null,
                        congruentSelected,
                        featureFlag.featureFlags
                    );
                }
            });
            return {
                optionsSelected: productUtil.product.currentFilters,
                dimensions: productUtil.product.dimensions,
                pricingURL: productUtil.product.priceURL,
                filteredItems: productUtil.product.filteredItems,
                selectedProductLot: productUtil.product.selectedProductLot,
                productVideos: productUtil.product.details.videos,
                productImages: productUtil.product.images,
                isProductInStock: productUtil.product.isProductInStock,
                isItemInStock: productUtil.product.isItemInStock,
                selectedLotDetails: productUtil.product.selectedLotDetails,
                hybridPrice: productUtil.product.hybridPrice,
                myAlertIndicator: productUtil.product.myAlertIndicator,
                ppFeatureColor: productUtil.product.ppFeatureColor,
                congruentFilters: productUtil.product.congruentFilters,
            };
        }
    )
);

export const selectIsItemOos = memoize((sourceMiniPDP) =>
    createSelector(
        [initProductOptions(sourceMiniPDP)],
        // todo refactor this and calculate isItemOos based on productOptions argument
        productOptions => !ProductModule.getItemInStockStatus(sourceMiniPDP ? miniProduct : baseProduct), // eslint-disable-line
    )
);

export const getAdditionalServices = memoize((sourceMiniPDP) =>
    createSelector([initProductOptions(sourceMiniPDP)], (productOptions) => {
        const selectedProductLot = get(
            productOptions,
            'selectedProductLot[0]',
            defaults.selectedProductLot
        );
        return get(selectedProductLot, 'additionalPartsAndServices', defaults.additionalServices);
    })
);

export const selectCurrentFilters = memoize((sourceMiniPDP) =>
    createSelector(
        [initProductOptions(sourceMiniPDP)],
        (productOptions) => productOptions.optionsSelected
    )
);

const getselectedOptions = memoize((sourceMiniPDP) =>
    createSelector(
        [initProductOptions(sourceMiniPDP)],
        (productOptions) => productOptions.optionsSelected
    )
);

/* istanbul ignore next */
export const selectReInitializeDimensions = memoize((sourceMiniPDP) =>
    createSelector([initProductOptions(sourceMiniPDP)], (productOptions) => {
        const productUtil = sourceMiniPDP ? miniProduct : baseProduct;
        const dimensions = productOptions.dimensions.concat();
        let countSaleandClearence = 0;
        forEach(dimensions, (dimension) => {
            const isAvailable = some(
                dimension.options,
                (optionValue) => optionValue.availabilityMessage !== ''
            );
            /* istanbul ignore next */
            if (!isAvailable && !productUtil.product.details.hybridIndicator) {
                forEach(dimension.options, (option) => {
                    option.availableInStock = true;
                    option.availabilityMessage = option.value;
                });
            }
            if (productUtil.product.details.hybridIndicator && dimension.id === COLOR_SKU_TOKEN) {
                if (!isAvailable) {
                    countSaleandClearence += 1;
                }
                /* istanbul ignore next */
                if (countSaleandClearence === 2) {
                    dimension.priceDisplay = false;
                }
                /** Hide the clearance section if all SKUs under clearance are OOS */
                if (dimension.priceType === 'clearance') {
                    const clearenceOOS = some(
                        dimension.options,
                        (optionValue) => optionValue.availableInStock !== false
                    );
                    if (!clearenceOOS) {
                        forEach(dimension.options, (option) => {
                            option.availabilityMessage = '';
                        });
                    }
                }
            }
        });

        return dimensions;
    })
);

export const nonSelectedProductOptions = memoize((sourceMiniPDP) =>
    createSelector(
        [selectReInitializeDimensions(sourceMiniPDP), getselectedOptions(sourceMiniPDP)],
        (dimensions, selectedOptions) => {
            const selectedType = selectedOptions.length
                ? selectedOptions.map((val) => val.type)
                : [];
            const unSelectedOptionName = dimensions.filter(
                (dimension) => selectedType.indexOf(dimension.id) < 0
            );
            return unSelectedOptionName;
        }
    )
);

export const selectedProductSkus = memoize((sourceMiniPDP) =>
    createSelector(
        [selectProductDetailsBySource(sourceMiniPDP), getselectedOptions(sourceMiniPDP)],
        (productDetails, selectedOptions) => {
            let selectedLot;
            if (productDetails && productDetails.id) {
                selectedLot = filter(productDetails.lots, { isDefault: true });
                /* istanbul ignore next */
                if (selectedOptions.length) {
                    const filterValues = map(
                        selectedOptions,
                        ({ option: value, dimensionName: name }) => ({ name, value })
                    );
                    selectedLot = filter(productDetails.lots, (obj) =>
                        find(obj.items, isSelectedOptionsEqual(filterValues))
                    );
                }
            }
            return selectedLot;
        }
    )
);

export const getSelectedColor = memoize((sourceMiniPDP) =>
    createSelector([getselectedOptions(sourceMiniPDP)], (optionsSelected) => {
        let selectedColor = null;

        if (optionsSelected) {
            const color = filter(optionsSelected, { dimensionName: 'color' });

            selectedColor = color && color.length ? color[0].name : null;
        }

        return selectedColor;
    })
);

/* istanbul ignore next */
const getDimensionValues = (productOptions, dimensionTypes) => {
    let dimensionValues = [];
    let dimension;
    if (productOptions.dimensions && dimensionTypes.length) {
        find(dimensionTypes, (dimensionId) => {
            const currentDimension = find(productOptions.dimensions, { id: dimensionId });
            if (currentDimension) {
                dimension = currentDimension;
                return true;
            }
            return false;
        });
        if (dimension) {
            dimension = ProductModule.removeUnavailableFilters(dimension);
        }
        dimensionValues = get(dimension, 'options', []);
    }
    return dimensionValues;
};

/* istanbul ignore next */
const getSizeDimension = memoize((sourceMiniPDP) =>
    createSelector([initProductOptions(sourceMiniPDP)], (productOptions) =>
        getDimensionValues(productOptions, ['WIDTH', 'SIZE', 'CHEST'])
    )
);

/* istanbul ignore next */
export const getSizes = memoize((sourceMiniPDP) =>
    createSelector([getSizeDimension(sourceMiniPDP)], (sizeDimension) => {
        if (sizeDimension.length) {
            return map(sizeDimension, 'value');
        }
        return null;
    })
);

export const getSelectedSize = memoize((sourceMiniPDP) =>
    createSelector([getselectedOptions(sourceMiniPDP)], (optionsSelected) => {
        let selectedSize = null;
        if (optionsSelected) {
            const shoeWidth = find(optionsSelected, { dimensionName: 'width' });
            selectedSize = get(shoeWidth, 'name', null);

            if (selectedSize) {
                return selectedSize;
            }

            const sizeDimension = find(optionsSelected, { dimensionName: 'size' });
            selectedSize = get(sizeDimension, 'name', null);

            if (selectedSize) {
                return selectedSize;
            }

            const chestSize = find(optionsSelected, { dimensionName: 'chest' });
            selectedSize = get(chestSize, 'name', null);

            if (selectedSize) {
                return selectedSize;
            }
        }
        return selectedSize;
    })
);

/* istanbul ignore next */
const getSizeRangeDimension = memoize((sourceMiniPDP) =>
    createSelector([initProductOptions(sourceMiniPDP)], (productOptions) =>
        getDimensionValues(productOptions, ['SIZE_RANGE'])
    )
);

/* istanbul ignore next */
export const getSizeRanges = memoize((sourceMiniPDP) =>
    createSelector([getSizeRangeDimension(sourceMiniPDP)], (sizeDimension) => {
        if (sizeDimension.length) {
            return map(sizeDimension, 'value');
        }
        return null;
    })
);

/* istanbul ignore next */
export const getAvailableSizes = memoize((sourceMiniPDP) =>
    createSelector([getSizeDimension(sourceMiniPDP)], (sizeDimension) => {
        if (sizeDimension.length) {
            const sizes = filter(
                sizeDimension,
                (dimension) =>
                    dimension.availableInStock &&
                    dimension.availabilityMessage.toLowerCase().indexOf('out of stock') === -1
            );
            return map(sizes, 'value');
        }
        return null;
    })
);

export const getSelectedProductSku = memoize((sourceMiniPDP) =>
    createSelector([initProductOptions(sourceMiniPDP)], (productOptions) =>
        get(productOptions, 'filteredItems[0].id')
    )
);

export const getFilteredSkuId = memoize((sourceMiniPDP) =>
    createSelector([initProductOptions(sourceMiniPDP)], (productOptions) => {
        if (
            productOptions &&
            productOptions.filteredItems &&
            productOptions.filteredItems.length === 1
        ) {
            return get(productOptions, 'filteredItems[0].id');
        }
        return null;
    })
);

/* istanbul ignore next */
export const getProductDefaultLot = createSelector(
    [selectProductDetailsBySource(true)],
    (productDetails) =>
        get(productDetails, 'lots', []).filter(({ isDefault }) => isDefault === true)[0]
);
