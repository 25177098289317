/* istanbul ignore file */
import React, { useState, Children, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Icon from 'yoda-core-components/lib/components/Icon/Icon';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import Play from 'yoda-core-components/lib/assets/svg/Dot_Video-Active.svg';
import VideoPlay from 'yoda-core-components/lib/assets/svg/Dot_Video-Inactive.svg';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import get from 'lodash/get';
import * as styles from './HorizontalCarousel.css';
import { setOnClickBeaconStatusForSwatchAltimageClick } from '../../actions/CriteoAction';

const cx = classNames.bind(styles);
const HORIZONTAL_SWIPE_THRESHOLD = 30;

const HorizontalCarousel = (props) => {
    const {
        carouselBulletTheme,
        carouselData,
        autoPlay,
        arrows,
        isPromoBanner,
        infinite,
        className,
        carouselSlideDefault = false,
        setActiveDefaultCarousel = () => {},
        enableMobileSwatchTap = false,
        enableVideoPlacementChange,
        isPDPImage,
        sponsoredProduct,
        OnClickBeacon,
        ppId,
        skuId,
        skuSwatch,
    } = props;
    const intialActiveIndex = 0;
    const [slideConfig, setSlideConfig] = useState({
        activeIndex: intialActiveIndex,
        tranformXvalueNext: 0,
    });
    const [xDown, setxDown] = useState(null);
    const [yDown, setyDown] = useState(null);
    const [isAutoPlayRunning, setAutoPlayRunning] = useState(true);
    const [isSponsorProductClick, setSponsorProductClick] = useState(false);
    const isAutoPlayRef = useRef(true);
    const isSlideCompleted = useRef(true);
    const filterdChildren = Children.toArray(props?.children).filter(Boolean);
    const isPrevButtonEnabled = slideConfig.activeIndex !== intialActiveIndex;
    const isNextButtonEnabled =
        filterdChildren?.length > 0 && slideConfig.activeIndex !== filterdChildren?.length - 1;
    const bulletTheme = isEmpty(carouselBulletTheme);
    const isTabDisplay = props.isCarouselTabDisplay;
    const autoPlayIntervelId = useRef();
    const mySlidesRef = useRef();
    const prevYdown = useRef(null);
    const prevXdown = useRef(null);
    const criteoData = useSelector((state) => get(state, 'criteo', {}));
    const dispatch = useDispatch();

    const {
        onClickBeaconCalledPPIDs = [],
        onClickBeaconCalledSwatchPPIDsAltImgClick = {},
        onClickBeaconCalledSwatchPPIDs = [],
    } = criteoData;

    const handleAutoPlay = () => {
        setSlideConfig((prevSlideConfig) => {
            return {
                activeIndex:
                    prevSlideConfig.activeIndex === filterdChildren?.length - 1
                        ? 0
                        : prevSlideConfig.activeIndex + 1,
                tranformXvalueNext:
                    prevSlideConfig.activeIndex === filterdChildren?.length - 1
                        ? 0
                        : prevSlideConfig.tranformXvalueNext + 100,
            };
        });
    };

    const handleSetIntervel = () => {
        if (autoPlay) {
            autoPlayIntervelId.current = setInterval(() => {
                setAutoPlayRunning(true);
                handleAutoPlay();
            }, 3000);
        }
    };
    useEffect(() => {
        isAutoPlayRef.current = isAutoPlayRunning;
    }, [isAutoPlayRunning]);

    useEffect(() => {
        if (!autoPlayIntervelId?.current) {
            handleSetIntervel();
        }
    }, [autoPlayIntervelId?.current]);

    useEffect(() => {
        if (carouselSlideDefault && enableMobileSwatchTap) {
            setSlideConfig(() => {
                return {
                    activeIndex: 0,
                    tranformXvalueNext: 0,
                };
            });
        }
    }, [carouselSlideDefault]);

    useEffect(() => {
        setSlideConfig(() => {
            return {
                activeIndex: 0,
                tranformXvalueNext: 0,
            };
        });
    }, [ppId]);

    const handleClick = (direction) => {
        clearInterval(autoPlayIntervelId?.current);
        if (isSlideCompleted?.current) {
            if (infinite) {
                if (direction === 'next') {
                    setSlideConfig((prevSlideConfig) => {
                        return {
                            activeIndex: isNextButtonEnabled ? prevSlideConfig.activeIndex + 1 : 0,
                            tranformXvalueNext: isNextButtonEnabled
                                ? prevSlideConfig.tranformXvalueNext + 100
                                : 0,
                        };
                    });
                    props.slideCallback(slideConfig.activeIndex + 1);
                }
                if (direction === 'prev') {
                    setSlideConfig((prevSlideConfig) => {
                        return {
                            activeIndex: isPrevButtonEnabled
                                ? prevSlideConfig.activeIndex - 1
                                : filterdChildren?.length - 1,
                            tranformXvalueNext: isPrevButtonEnabled
                                ? prevSlideConfig.tranformXvalueNext - 100
                                : (filterdChildren?.length - 1) * 100,
                        };
                    });
                    props.slideCallback(slideConfig.activeIndex - 1);
                }
            } else {
                if (direction === 'next') {
                    setSlideConfig((prevSlideConfig) => {
                        return {
                            activeIndex: isNextButtonEnabled
                                ? prevSlideConfig.activeIndex + 1
                                : prevSlideConfig.activeIndex,
                            tranformXvalueNext: isNextButtonEnabled
                                ? prevSlideConfig.tranformXvalueNext + 100
                                : prevSlideConfig.tranformXvalueNext,
                        };
                    });
                    props.slideCallback(slideConfig.activeIndex + 1);
                }
                if (direction === 'prev') {
                    setSlideConfig((prevSlideConfig) => {
                        return {
                            activeIndex: isPrevButtonEnabled
                                ? prevSlideConfig.activeIndex - 1
                                : prevSlideConfig.activeIndex,
                            tranformXvalueNext: isPrevButtonEnabled
                                ? prevSlideConfig.tranformXvalueNext - 100
                                : prevSlideConfig.tranformXvalueNext,
                        };
                    });
                    props.slideCallback(slideConfig.activeIndex - 1);
                }
            }
        }
    };

    const currentSlide = (index) => {
        clearInterval(autoPlayIntervelId?.current);
        if (enableMobileSwatchTap) {
            setActiveDefaultCarousel();
        }
        setSlideConfig(() => {
            return {
                activeIndex: index,
                tranformXvalueNext: 100 * index,
            };
        });
        props.slideCallback(index);

        const currentSwatchSkuId =
            onClickBeaconCalledSwatchPPIDs?.[onClickBeaconCalledSwatchPPIDs?.length - 1];

        if (currentSwatchSkuId) {
            if (onClickBeaconCalledSwatchPPIDsAltImgClick?.[`${currentSwatchSkuId}_SkuId`]?.skuId) {
                if (
                    onClickBeaconCalledSwatchPPIDsAltImgClick?.[`${currentSwatchSkuId}_SkuId`]
                        ?.skuId === skuId &&
                    !onClickBeaconCalledSwatchPPIDsAltImgClick?.[`${currentSwatchSkuId}_SkuId`]
                        ?.altImageVisited
                ) {
                    navigator.sendBeacon(`https:${OnClickBeacon}`);
                    dispatch(
                        setOnClickBeaconStatusForSwatchAltimageClick(
                            `${currentSwatchSkuId}_SkuId`,
                            { altImageVisited: true, skuId }
                        )
                    );
                }
                if (
                    onClickBeaconCalledSwatchPPIDsAltImgClick?.[`${currentSwatchSkuId}_SkuId`]
                        ?.skuId !== skuId &&
                    !onClickBeaconCalledSwatchPPIDsAltImgClick?.[`${skuId}_SkuId`]?.altImageVisited
                ) {
                    navigator.sendBeacon(`https:${OnClickBeacon}`);
                    dispatch(
                        setOnClickBeaconStatusForSwatchAltimageClick(`${skuId}_SkuId`, {
                            altImageVisited: true,
                            skuId,
                        })
                    );
                }
            } else if (
                !onClickBeaconCalledSwatchPPIDsAltImgClick?.[`${currentSwatchSkuId}_SkuId`]
                    ?.altImageVisited
            ) {
                navigator.sendBeacon(`https:${OnClickBeacon}`);
                dispatch(
                    setOnClickBeaconStatusForSwatchAltimageClick(`${currentSwatchSkuId}_SkuId`, {
                        altImageVisited: true,
                        skuId,
                    })
                );
            }
        }
        if (
            !currentSwatchSkuId &&
            !onClickBeaconCalledSwatchPPIDsAltImgClick?.[`${skuId}_SkuId`]?.altImageVisited
        ) {
            navigator.sendBeacon(`https:${OnClickBeacon}`);
            dispatch(
                setOnClickBeaconStatusForSwatchAltimageClick(`${skuId}_SkuId`, {
                    altImageVisited: true,
                    skuId,
                })
            );
        }
    };

    const isHorizontalSwipeDetected = (e) => {
        const currentX = e?.touches?.[0].clientX;
        const currentY = e?.touches?.[0].clientY;

        const deltaX = currentX - xDown;
        const deltaY = currentY - yDown;

        const angle = Math.abs(Math.atan2(deltaY, deltaX) * (180 / Math.PI));

        return angle < HORIZONTAL_SWIPE_THRESHOLD;
    };

    const handleStartEvent = (e) => {
        const htmlDocmentElement = window?.document?.querySelector('body');
        if (isHorizontalSwipeDetected(e)) {
            htmlDocmentElement.style.overflow = 'hidden';
            htmlDocmentElement.style.touchAction = 'none';
        }
        clearInterval(autoPlayIntervelId?.current);
        const firstTouch = e?.touches?.[0];
        setxDown(firstTouch?.clientX);
        setyDown(firstTouch?.clientY);
    };

    const handleMoveEvent = (e) => {
        const currentSwatchSkuId =
            onClickBeaconCalledSwatchPPIDs?.[onClickBeaconCalledSwatchPPIDs?.length - 1];
        if (sponsoredProduct && OnClickBeacon) {
            if (currentSwatchSkuId) {
                if (
                    onClickBeaconCalledSwatchPPIDsAltImgClick?.[`${currentSwatchSkuId}_SkuId`]
                        ?.skuId
                ) {
                    if (
                        onClickBeaconCalledSwatchPPIDsAltImgClick?.[`${currentSwatchSkuId}_SkuId`]
                            ?.skuId === skuId &&
                        !onClickBeaconCalledSwatchPPIDsAltImgClick?.[`${currentSwatchSkuId}_SkuId`]
                            ?.altImageVisited
                    ) {
                        navigator.sendBeacon(`https:${OnClickBeacon}`);
                        dispatch(
                            setOnClickBeaconStatusForSwatchAltimageClick(
                                `${currentSwatchSkuId}_SkuId`,
                                { altImageVisited: true, skuId }
                            )
                        );
                    }
                    if (
                        onClickBeaconCalledSwatchPPIDsAltImgClick?.[`${currentSwatchSkuId}_SkuId`]
                            ?.skuId !== skuId &&
                        !onClickBeaconCalledSwatchPPIDsAltImgClick?.[`${skuId}_SkuId`]
                            ?.altImageVisited
                    ) {
                        navigator.sendBeacon(`https:${OnClickBeacon}`);
                        dispatch(
                            setOnClickBeaconStatusForSwatchAltimageClick(`${skuId}_SkuId`, {
                                altImageVisited: true,
                                skuId,
                            })
                        );
                    }
                } else if (
                    !onClickBeaconCalledSwatchPPIDsAltImgClick?.[`${currentSwatchSkuId}_SkuId`]
                        ?.altImageVisited
                ) {
                    navigator.sendBeacon(`https:${OnClickBeacon}`);
                    dispatch(
                        setOnClickBeaconStatusForSwatchAltimageClick(
                            `${currentSwatchSkuId}_SkuId`,
                            { altImageVisited: true, skuId }
                        )
                    );
                }
            }
            if (
                !currentSwatchSkuId &&
                !onClickBeaconCalledSwatchPPIDsAltImgClick?.[`${skuId}_SkuId`]?.altImageVisited
            ) {
                navigator.sendBeacon(`https:${OnClickBeacon}`);
                dispatch(
                    setOnClickBeaconStatusForSwatchAltimageClick(`${skuId}_SkuId`, {
                        altImageVisited: true,
                        skuId,
                    })
                );
            }
        }

        const htmlDocmentElement = window?.document?.querySelector('body');
        if (isHorizontalSwipeDetected(e)) {
            htmlDocmentElement.style.overflow = 'hidden';
            htmlDocmentElement.style.touchAction = 'none';
        }
        clearInterval(autoPlayIntervelId?.current);
        setAutoPlayRunning(false);

        if (!xDown || !yDown || xDown === prevXdown.current || yDown === prevYdown.current) {
            return;
        }

        const xUp = e.touches[0].clientX;
        const yUp = e.touches[0].clientY;

        const xDiff = xDown - xUp;
        const yDiff = yDown - yUp;

        prevYdown.current = yDown;
        prevXdown.current = xDown;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) {
                /* right swipe */
                handleClick('next');
                isSlideCompleted.current = false;
            } else {
                /* left swipe */
                handleClick('prev');
                isSlideCompleted.current = false;
            }
        }
        setxDown(null);
        setyDown(null);
        if (enableMobileSwatchTap) {
            setActiveDefaultCarousel();
        }
    };

    const handleEndEvent = () => {
        isSlideCompleted.current = true;
        const htmlDocmentElement = window?.document?.querySelector('body');
        htmlDocmentElement?.style?.removeProperty('overflow');
        htmlDocmentElement?.style?.removeProperty('touch-action');
        handleSetIntervel();
    };
    const handleOnTouchCancel = () => {
        isSlideCompleted.current = true;
        const htmlDocmentElement = window?.document?.querySelector('body');
        htmlDocmentElement?.style?.removeProperty('overflow');
        htmlDocmentElement?.style?.removeProperty('touch-action');
    };
    const handleOnMouseEnter = () => {
        clearInterval(autoPlayIntervelId?.current);
    };
    const handleonMouseLeave = () => {
        handleSetIntervel();
    };
    const tabDisplayStyle = dt(['flex-auto', 'cursor-pointer']);
    const tabDisplayActiveStyle = dt([
        'border-b-2',
        'border-gray-70',
        'border-solid',
        'transition',
        'duration-500',
        'ease-in',
    ]);
    const dotStyle = dt([
        'cursor-pointer',
        enableVideoPlacementChange ? 'h-[0.5rem]' : 'h-[0.625rem]',
        enableVideoPlacementChange ? 'w-[0.5rem]' : 'w-[0.625rem]',
        !enableVideoPlacementChange && !isPromoBanner && 'm-[5px]',
        enableVideoPlacementChange && 'mx-[4px]',
        !enableVideoPlacementChange && 'bg-gray-25',
        enableVideoPlacementChange && 'border',
        enableVideoPlacementChange && 'border-solid',
        enableVideoPlacementChange && 'border-gray-60',
        'rounded-half',
        'inline-block',
        'transition',
        'duration-500',
        'ease-in',
    ]);
    const dotActiveStyle = dt(['bg-gray-60']);
    const playStyle = dt(['my-0', enableVideoPlacementChange ? 'mx-1' : 'mx-2', 'flex']);
    const handlePagination = (index) => {
        return (
            <li className={dt(['flex', 'justify-center', 'align-center'])}>
                {carouselData?.[index]?.icon === 'play' ? (
                    isPDPImage && enableVideoPlacementChange ? (
                        <button
                            type="text"
                            className={cx('play', playStyle, {
                                playButtonVideoPlacement: slideConfig.activeIndex === index,
                            })}
                            key={index + 1}
                        >
                            <Icon iconType="svg" width="8px" height="8px" viewBox="0 0 8 8">
                                {slideConfig.activeIndex === index ? <Play /> : <VideoPlay />}
                            </Icon>
                        </button>
                    ) : (
                        <button
                            type="button"
                            className={cx('play', playStyle, {
                                playButton: slideConfig.activeIndex === index,
                            })}
                            key={index + 1}
                            onClick={() => currentSlide(index)}
                        >
                            <Icon iconType="svg" width="10px" height="10px" viewBox="0 0 25 25">
                                <Play />
                            </Icon>
                        </button>
                    )
                ) : isPDPImage && enableVideoPlacementChange ? (
                    <div
                        className={cx(
                            'dot',
                            dotStyle,
                            `${bulletTheme ? '' : carouselBulletTheme?.bullet}`,
                            `${slideConfig.activeIndex === index && dotActiveStyle}`
                        )}
                        key={index + 1}
                    />
                ) : (
                    <button
                        type="button"
                        aria-label={`go to slide ${index + 1}`}
                        className={
                            isTabDisplay
                                ? cx('tabDisplay', tabDisplayStyle, {
                                      active: slideConfig.activeIndex === index,
                                  })
                                : cx(
                                      'dot',
                                      dotStyle,
                                      `${bulletTheme ? '' : carouselBulletTheme?.bullet}`,
                                      `${slideConfig.activeIndex === index && dotActiveStyle}`
                                  )
                        }
                        key={index + 1}
                        onClick={() => currentSlide(index)}
                    />
                )}
            </li>
        );
    };

    const handleTabPagination = (index) => {
        return (
            <button
                type="button"
                aria-label={`go to slide ${index + 1}`}
                className={
                    isTabDisplay
                        ? `${cx('tabDisplay', tabDisplayStyle)} ${
                              slideConfig.activeIndex === index && tabDisplayActiveStyle
                          }`
                        : cx(
                              'dot',
                              dotStyle,
                              `${bulletTheme ? '' : carouselBulletTheme?.bullet}`,
                              `${slideConfig.activeIndex === index && dotActiveStyle}`
                          )
                }
                key={index + 1}
                onClick={() => currentSlide(index)}
            />
        );
    };
    const tabContainerStyles = dt([
        'flex',
        'border-b-2',
        'border-light-white',
        'border-solid',
        'h-0.5',
    ]);
    const bulletContainerStyles = dt([
        'text-center',
        'relative',
        enableVideoPlacementChange ? 'p-2' : 'pt-2',
    ]);
    const handleBulletsContainer = () => {
        return (
            <div
                className={
                    bulletTheme
                        ? isTabDisplay
                            ? tabContainerStyles
                            : bulletContainerStyles
                        : carouselBulletTheme?.bulletContainer
                }
            >
                {!isTabDisplay ? (
                    <ul
                        className={dt([
                            'flex',
                            'justify-center',
                            'items-center',
                            'pb-1',
                            'smMd:pb-0',
                        ])}
                    >
                        {Children.map(filterdChildren, (child, index) =>
                            child ? <>{handlePagination(index)}</> : null
                        )}
                    </ul>
                ) : (
                    Children.map(filterdChildren, (child, index) =>
                        child ? <>{handleTabPagination(index)}</> : null
                    )
                )}
            </div>
        );
    };
    const slideContainerStyles = dt([
        'flex',
        'items-center',
        'content-center',
        'w-full',
        'overflow-hidden',
    ]);
    const mySlidesStyles = dt(['flex-shrink-0', 'w-full']);
    const videoSlideHeightStyle = props.isPDPImage
        ? dt(['smMd:aspect-square', 'smMd:flex', 'smMd:items-center'])
        : '';
    return (
        <>
            <div
                className={`${cx('slideshow-container', className, {
                    'slick-slider': isPromoBanner,
                })} ${dt(['relative'])}`}
            >
                <div
                    className={cx('slideContainer', slideContainerStyles)}
                    onMouseEnter={(mouseOverEvent) => {
                        handleOnMouseEnter(mouseOverEvent);
                    }}
                    onMouseLeave={(mouseOutEvent) => {
                        handleonMouseLeave(mouseOutEvent);
                    }}
                >
                    {Children.map(filterdChildren, (child, index) =>
                        child ? (
                            <div
                                className={cx(
                                    'mySlides',
                                    mySlidesStyles,
                                    {
                                        active: slideConfig.activeIndex === index,
                                    },
                                    videoSlideHeightStyle
                                )}
                                ref={mySlidesRef}
                                key={`slide-${index}`}
                                style={
                                    isAutoPlayRef?.current && autoPlay
                                        ? {
                                              opacity: slideConfig.activeIndex === index ? 1 : 0,
                                              display:
                                                  slideConfig.activeIndex === index
                                                      ? 'block'
                                                      : 'none',
                                              transform: `translateX(-${0}%)`,
                                          }
                                        : {
                                              transform: `translateX(-${slideConfig.tranformXvalueNext}%)`,
                                              transition: 'all 0.6s linear',
                                              outline: 'none',
                                          }
                                }
                                onTouchStart={(touchStartEvent) => {
                                    handleStartEvent(touchStartEvent);
                                }}
                                onTouchMove={(touchMoveEvent) => {
                                    handleMoveEvent(touchMoveEvent);
                                }}
                                onTouchEnd={(touchEndEvent) => {
                                    handleEndEvent(touchEndEvent);
                                }}
                                onTouchCancel={(touchCanceledEvent) => {
                                    handleOnTouchCancel(touchCanceledEvent);
                                }}
                            >
                                {child}
                            </div>
                        ) : null
                    )}
                    {autoPlay && handleBulletsContainer()}
                </div>
                {isPrevButtonEnabled && (
                    <button
                        aria-label="go to previous slide"
                        type="button"
                        className={cx('prev')}
                        style={{ display: arrows === true ? 'block' : 'none' }}
                        onClick={(e) => {
                            e.preventDefault();
                            handleClick('prev');
                        }}
                    />
                )}
                {isNextButtonEnabled && (
                    <button
                        aria-label="go to next slide"
                        type="button"
                        className={cx('next')}
                        style={{ display: arrows === true ? 'block' : 'none' }}
                        onClick={(e) => {
                            e.preventDefault();
                            handleClick('next');
                        }}
                    />
                )}
                {!autoPlay && handleBulletsContainer()}
            </div>
        </>
    );
};
HorizontalCarousel.defaultProps = {
    arrows: false,
    infinite: false,
    autoPlay: false,
    carouselBulletTheme: { bullet: '', bulletContainer: '' },
    carouselData: [],
    isCarouselTabDisplay: false,
    isPromoBanner: false,
    slideCallback: noop,
    isPDPImage: false,
    className: '',
};
HorizontalCarousel.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    arrows: PropTypes.bool,
    infinite: PropTypes.bool,
    autoPlay: PropTypes.bool,
    carouselBulletTheme: PropTypes.object,
    carouselData: PropTypes.array,
    isCarouselTabDisplay: PropTypes.bool,
    isPromoBanner: PropTypes.bool,
    slideCallback: PropTypes.func,
    isPDPImage: PropTypes.bool,
    className: PropTypes.string,
};
export default HorizontalCarousel;
