export const CRITEO_GET_REQUEST = 'CRITEO_GET_REQUEST';
export const CRITEO_GET_SUCCESS = 'CRITEO_GET_SUCCESS';
export const CRITEO_GET_GROUP_BEACONS = 'CRITEO_GET_GROUP_BEACONS';
export const CRITEO_GET_ERROR = 'CRITEO_GET_ERROR';
export const CRITEO_CLEAR_DATA = 'CRITEO_CLEAR_DATA';
export const CRITEO_WAIT_FOR_DATA = 'CRITEO_WAIT_FOR_DATA';
export const SPONSORED_GET_RESULTS = 'SPONSORED_GET_RESULTS';
export const CRITEO_SET_ON_LOAD_BEACON_STATUS = 'CRITEO_SET_ON_LOAD_BEACON_STATUS';
export const CRITEO_SET_ON_VIEW_BEACON_STATUS = 'CRITEO_SET_ON_VIEW_BEACON_STATUS';
export const CRITEO_SET_ON_CLICK_BEACON_STATUS = 'CRITEO_SET_ON_CLICK_BEACON_STATUS';
export const CRITEO_SET_ON_BASKET_BEACON_STATUS = 'CRITEO_SET_ON_BASKET_BEACON_STATUS';
export const CRITEO_SET_ON_WISHLIST_BEACON_STATUS = 'CRITEO_SET_ON_WISHLIST_BEACON_STATUS';
export const CRITEO_SET_ON_CLICK_BEACON_STATUS_SWATCH = 'CRITEO_SET_ON_CLICK_BEACON_STATUS_SWATCH';
export const CRITEO_SET_ON_CLICK_BEACON_STATUS_FOR_SWATCH_ALT_IMAGE_CLICK =
    'CRITEO_SET_ON_CLICK_BEACON_STATUS_FOR_SWATCH_ALT_IMAGE_CLICK';
