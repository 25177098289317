import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import classNames from 'classnames/bind';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import PromotionalBanner from '../PromotionalBanner/PromotionalBanner';
import DefaultTemplate from './TopMiniNav.config';
import * as styles from './TopMiniNav.css';
import { selectIsAccessibleV2Enabled } from '../../selectors/accessibleSelector';

const cx = classNames.bind(styles);

export class TopMiniNav extends Component {
    static propTypes = {
        deviceType: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        enableSitePromo: PropTypes.bool,
        disableAccessViewFromHeader: PropTypes.bool,
        AccessibleView: PropTypes.object,
        isAccessibleV2Enabled: PropTypes.bool,
        isNewHeader: PropTypes.bool,
    };

    static defaultProps = {
        deviceType: {},
        enableSitePromo: false,
        disableAccessViewFromHeader: false,
        AccessibleView: {},
        isAccessibleV2Enabled: false,
        isNewHeader: false,
    };

    static onUsableNetAnchorClicked() {
        /* istanbul ignore next */
        if (typeof window !== 'undefined' && window.enableUsableNetAssistive) {
            window.enableUsableNetAssistive();
        }
    }

    shouldComponentUpdate() {
        return false; // since it's controlled by a 3rd party
    }

    render() {
        const {
            enableSitePromo,
            deviceType,
            AccessibleView,
            disableAccessViewFromHeader,
            isAccessibleV2Enabled,
            isNewHeader,
        } = this.props;

        const accessibleViewStyles = dt([
            // 'sm:hidden',
            'lg:flex',
            'sm:z-[2]',
            'lg:absolute',
            'sm:top-[32%]',
            'items-center',
            'lg:right-4',
            'text-black',
            'text-small',
            'underline',
        ]);
        const toRenderAccessibleAnchor = !disableAccessViewFromHeader && AccessibleView;
        const devictTypeClass = deviceType.isDesktop
            ? `${dt([
                  'sm:relative',
                  'sm:z-[8]',
                  'sm:w-full',
                  'sm:bg-white',
                  'sm:text-black',
                  'sm:min-h-[36px]',
              ])} ${'topMiniNavBlackBar'}`
            : `${dt(['sm:relative', 'sm:z-[8]', 'w-full'])} ${'topMiniNavMain'}`;
        const headerClass = isNewHeader ? 'newHeader' : '';
        const disableSitePromoClass =
            !enableSitePromo && `${dt(['sm:top-[2px'])} ${'disableSitePromoView'}`;

        const showAccessibilityContent = () => {
            return (
                toRenderAccessibleAnchor &&
                (isAccessibleV2Enabled ? (
                <a // eslint-disable-line
                        id="header-accessible-view" // adding a unique id to track accessible view in Activity Map
                        data-automation-id="accessible-view"
                        className={`UsableNetAssistive ${cx(
                            'accessibleView',
                            accessibleViewStyles,
                            disableSitePromoClass
                        )}`}
                        onClick={TopMiniNav.onUsableNetAnchorClicked}
                        href="#"
                        title="By activating this link you will enable accessibility for all the data and features of the site"
                    dangerouslySetInnerHTML={{ __html: AccessibleView.name }} // eslint-disable-line
                        suppressHydrationWarning
                    ></a>
                ) : (
                    <a
                        className={`${accessibleViewStyles} ${cx('accessibleView')}`}
                        id="header-accessible-view" // adding a unique id to track accessible view in Activity Map
                        data-automation-id="accessible-view"
                        href={AccessibleView.link}
                    >
                        {AccessibleView.name}
                    </a>
                ))
            );
        };

        return (
            <>
                <div
                    id="primaryHeader"
                    data-automation-id="sitePromoAccessibleView"
                    className={cx(devictTypeClass, headerClass)}
                >
                    {/* adding accessible view link */}
                    {deviceType.isDesktop ? (
                        showAccessibilityContent()
                    ) : (
                        <div
                            className={`${dt([
                                'sm:w-full',
                                'sm:text-center',
                                'sm:bg-[#F2F2F2]',
                                'sm:pb-[5px]',
                                'sm:h-11',
                                'sm:p-[10px]',
                            ])}`}
                        >
                            {' '}
                            {showAccessibilityContent()}{' '}
                        </div>
                    )}
                    {enableSitePromo && (
                        <PromotionalBanner isNewHeader deviceType={this.props.deviceType} />
                    )}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    AccessibleView: get(state, 'context.preferences.AccessibleView', DefaultTemplate),
    isAccessibleV2Enabled: selectIsAccessibleV2Enabled(state),
});

export default connect(mapStateToProps)(TopMiniNav);
