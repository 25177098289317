import OptInReducer from './OptInReducer';
import ProductSearchReducer from './ProductSearchReducer';
import ProductCartridgeReducer from './ProductCartridgeReducer';
import SessionReducer from './SessionReducer';
import ItemCountReducer from './ItemCountReducer';
import locationServiceReducer from './LocationServiceReducer';
import WeeklyAdsReducer from './WeeklyAdsReducer';
import CouponsReducer from './CouponsReducer';
import StoreReducer from './StoreReducer';
import BreadCrumbNavReducer from './BreadCrumbNavReducer';
import ProductSearchDetailReducer from './ProductSearchDetailReducer';
import AdjustmentReducer from './AdjustmentReducer';
import AppliedAdjustmentsReducer from './AppliedAdjustmentsReducer';
import UpdatedAdjustmentReducer from './UpdatedAdjustmentReducer';
import MessagesReducer from './MessagesReducer';
import savedItemResponseReducer from './SavedItemResponseReducer';
import FindStoresReducer from './FindStoresReducer';
import LoadingReducer from './LoadingReducer';
import OfferDetailsReducer from './PromotionalBannerReducer';
import SavedItemsReducer from './SavedItemsReducer';
import AccountReducer from './AccountReducer';
import FindAStorePageReducer from './FindAStorePageReducer';
import HamburgerReducer from './HambergurReducer';
import MTLSavedLists from './SavedListToolTip';
import ErrorPageReducer from './ErrorPageReducer';
import AddToCartReducer from './AddToCartReducer';
import AccessTokenProviderReducer from './AccessTokenProviderReducer';
import OverlayReducer from './OverlayReducer';
import RegionReducer from './FetchRegionReducers/FetchRegionReducer';
import ObtainRegionZoneReducer from './FetchRegionReducers/ObtainRegionZoneReducer';
import ShowPreviewZoneReducer from './ShowPreviewZoneReducer';
import IrisReducer from './IrisReducer';
import cmReducer from './CMPageReducer';
import CustomOptionsReducer from './CustomOptionsReducer';
import EcoRebatesReducer from './EcoRebatesReducer';
import InlineImageLoaderReducer from './InlineImageLoaderReducer';
import LazyReducer from '../lazy/reducers/LazyReducer';
import ColorSwatchesReducer from './ColorSwatchesReducer';
import IrisGlobalNavigationReducer from './IrisGlobalNavigationReducer';
import IrisHambergurReducer from './IrisHambergurReducer';
import ShowShopDepartmentReducer from './ShowShopDepartmentReducer';
import stickyTimerDetailsReducer from './StickyTimerReducer';
import ContextReducer from './ContextReducer';
import CouponListReducer from './CouponListReducer';
import CouponDetailsModalReducer from './CouponDetailsModalReducer';
import PdpNameSpaceReducerCreator from './PdpNameSpaceReducerCreator';
import DigitalDataReducer from './DigitalDataReducer';
import BgWhiteReducer from './BgWhiteReducer';
import ApiErrorReducer from './ApiErrorReducer';
import HamburgerSliderReducer from './HamburgerSliderReducer';
import IrisGlobalFooterNavigationReducer from './IrisGlobalFooterNavigationLinkReducer';
import cmSwitchWidgetReducer from './CMSwitchWidgetReducer';
import irisS1TargeterReducer from './IRISS1TargeterReducer';
import brightEdgeReducer from './BrightEdgeReducer';
import plpSwitchWidgetReducer from './PLPSwitchWidgetReducer';
import criteoReducer from './CriteoReducer';
import ProductOptionsForCongruentPPReducer from './ProductOptionsForCongruentPPReducer';

const rootReducer = {
    context: ContextReducer,
    IrisGlobalNavigation: IrisGlobalNavigationReducer,
    irishambergurMenu: IrisHambergurReducer,
    locationServiceReducer,
    weeklyAdsMessages: WeeklyAdsReducer,
    productSearchResults: ProductSearchReducer,
    optin: OptInReducer,
    productCartridge: ProductCartridgeReducer,
    session: SessionReducer,
    bagItemCount: ItemCountReducer,
    coupons: CouponsReducer,
    selectedStore: StoreReducer,
    breadCrumbsData: BreadCrumbNavReducer,
    productSearchDetailResult: ProductSearchDetailReducer,
    couponApplicationState: AdjustmentReducer,
    appliedAdjustments: AppliedAdjustmentsReducer,
    isAdjustmentsUpdated: UpdatedAdjustmentReducer,
    messages: PdpNameSpaceReducerCreator(MessagesReducer, false, 'messages'),
    miniPdpMessages: PdpNameSpaceReducerCreator(MessagesReducer, true, 'miniPdpMessages'),
    savedItemStatus: savedItemResponseReducer,
    findStoresDetails: FindStoresReducer,
    isLoading: LoadingReducer,
    promotionalBannerData: OfferDetailsReducer,
    savedItems: SavedItemsReducer,
    accounts: AccountReducer,
    hambergurMenu: HamburgerReducer,
    findAStorePageInfo: FindAStorePageReducer,
    ErrorPage: ErrorPageReducer,
    tokenInfo: AccessTokenProviderReducer,
    MTLSavedLists,
    addToCartSite: AddToCartReducer,
    regionalPricing: RegionReducer,
    showOverlay: OverlayReducer,
    regionZonefromLocation: ObtainRegionZoneReducer,
    showPreviewZone: ShowPreviewZoneReducer,
    irisData: IrisReducer,
    cmData: cmReducer,
    cmSwitchWidgetData: cmSwitchWidgetReducer,
    customOptions: CustomOptionsReducer,
    rebateInfo: PdpNameSpaceReducerCreator(EcoRebatesReducer, false, 'rebateInfo'),
    miniRebateInfo: PdpNameSpaceReducerCreator(EcoRebatesReducer, true, 'miniRebateInfo'),
    isInlineImageLoading: InlineImageLoaderReducer,
    lazy: LazyReducer,
    colorSwatches: PdpNameSpaceReducerCreator(ColorSwatchesReducer, false, 'colorSwatches'),
    miniPdpcolorSwatches: PdpNameSpaceReducerCreator(
        ColorSwatchesReducer,
        true,
        'miniPdpcolorSwatches'
    ),
    showShopDepartment: ShowShopDepartmentReducer,
    stickyTimerData: stickyTimerDetailsReducer,
    couponList: CouponListReducer,
    couponDetailsModal: CouponDetailsModalReducer,
    digitalData: DigitalDataReducer,
    showBgWhite: BgWhiteReducer,
    apiErrorMsgs: ApiErrorReducer,
    IrisFooterGlobalNavigation: IrisGlobalFooterNavigationReducer,
    HamburgerSlider: HamburgerSliderReducer,
    irisS1Targter: irisS1TargeterReducer,
    brightEdgeData: brightEdgeReducer,
    switchWidgetData: plpSwitchWidgetReducer,
    criteo: criteoReducer,
    congruentSelected: ProductOptionsForCongruentPPReducer,
};

export default rootReducer;
